import dynamic from 'next/dynamic';

import styles from './two-column-text-intro.module.scss';
import {
  MarkdownWrapper,
  StyledMarkdown,
  TwoColumnTextIntroWrapper,
} from './two-column-text-intro.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface TwoColumnTextIntroProps {
  header?: string;
  introText?: string;
  text?: string;
}

export const TwoColumnTextIntro: React.FC<TwoColumnTextIntroProps> = ({
  header,
  introText,
  text,
}) => {
  return (
    <TwoColumnTextIntroWrapper>
      <div>
        <HTag type="h2" styleType="h4">
          {header}
        </HTag>
      </div>
      <div>
        <HTag
          type="p"
          styleType="p2-paragraph"
          className={styles['intro-text']}
        >
          {introText}
        </HTag>
        <MarkdownWrapper>
          <StyledMarkdown>{text ?? ''}</StyledMarkdown>
        </MarkdownWrapper>
      </div>
    </TwoColumnTextIntroWrapper>
  );
};
