import dynamic from 'next/dynamic';

import { Button } from '../button/button';

import {
  ButtonWrapper,
  MoreNewsWrapperWrapper,
} from './more-news-wrapper.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface MoreNewsWrapperProps {
  bigTitle?: boolean;
  title?: string;
  CTAText?: string;
  CTAUrl?: string;
  children?: React.ReactNode;
}

export const MoreNewsWrapper: React.FC<MoreNewsWrapperProps> = ({
  bigTitle = false,
  title,
  children,
  CTAText,
  CTAUrl,
}) => {
  return (
    <MoreNewsWrapperWrapper data-test="MoreNewsWrapper">
      <HTag type="h2" styleType={bigTitle ? 'h1' : 'h2'}>
        {title}
      </HTag>
      {children}
      <ButtonWrapper>
        <Button to={CTAUrl}>{CTAText}</Button>
      </ButtonWrapper>
    </MoreNewsWrapperWrapper>
  );
};
