import { useId } from 'react';

import dynamic from 'next/dynamic';
import { SwiperSlide } from 'swiper/react';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  HeroBlockFragment,
  SliderBlockFragment,
} from '@hultafors/hultaforsgroup/types';

import { HeroBlock } from '../hero-block/hero-block';

import styles from './slider-block.module.scss';

const SliderBlockShared = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SliderBlockShared),
);

interface SliderBlockProps extends SliderBlockFragment {
  priority?: boolean;
}

export const SliderBlock: React.FC<SliderBlockProps> = ({
  arrows,
  dots,
  delay,
  heroes,
  slideAnimation,
  title,
  priority,
}) => {
  const uid = useId();
  const { globalContent: global } = useGlobal();

  function heroMapper(hero: HeroBlockFragment) {
    return (
      <SwiperSlide
        className="swiper-slide"
        key={`SliderBlock-${uid}-${hero.id}`}
      >
        <HeroBlock {...hero} priority={priority} />
      </SwiperSlide>
    );
  }

  return (
    <SliderBlockShared
      blockProps={{
        arrows,
        delay,
        dots,
        slideAnimation,
      }}
      ariaLabelNext={global?.nextLabel || ''}
      ariaLabelPrevious={global?.previousLabel || ''}
      ariaLabelSliderNavigation={global?.sliderNavigationLabel || ''}
      className={styles['root']}
    >
      {heroes.map(heroMapper)}
    </SliderBlockShared>
  );
};
