import clsx from 'clsx';
import dynamic from 'next/dynamic';

import type { TextBlockFragment } from '@hultafors/hultaforsgroup/types';

import { Markdown } from '../markdown/markdown';

import styles from './text-block.module.scss';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

type TextBlockProps = Omit<TextBlockFragment, 'id'>;

export const TextBlock: React.FC<TextBlockProps> = ({
  smallHeader,
  header,
  text,
  introText,
}) => {
  return (
    <section className={clsx('content-container', styles['wrapper'])}>
      {header && (
        <HTag
          type="h2"
          styleType={smallHeader ? 'h5' : 'h4'}
          className={styles['title']}
        >
          {header}
        </HTag>
      )}
      {introText && (
        <HTag type="h3" styleType="p2-paragraph" className={styles['intro']}>
          {introText}
        </HTag>
      )}
      {text && (
        <div className={styles['text']}>
          <Markdown>{text}</Markdown>
        </div>
      )}
    </section>
  );
};
