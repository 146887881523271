import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  ContentAreaData,
  ContentAreaField,
  isArticlesBlock,
  isBulletListBlock,
  isDocumentLinkBlock,
  isEmployeeBlock,
  isHeroBlock,
  isImageBlock,
  isJobsListBlock,
  isNewsListBlock,
  isOfficesBlock,
  isOurBrandsBlock,
  isPlugBlock,
  isSimpleTitleAndTextBlock,
  isSliderBlock,
  isStatementBlock,
  isTextBlock,
  isTwoColumnTextImageBlock,
  isTwoColumnTextIntroBlock,
  isVideoBlock,
  JobsResponse,
  NewsResponse,
} from '@hultafors/hultaforsgroup/types';

const ArticlesBlock = dynamic(() =>
  import('../articles-block/articles-block').then(
    (module) => module.ArticlesBlock,
  ),
);
const BulletListBlock = dynamic(() =>
  import('../bullet-list-block/bullet-list-block').then(
    (module) => module.BulletListBlock,
  ),
);
const DocumentLinkBlock = dynamic(() =>
  import('../documentLinkBlock/DocumentLinkBlock').then(
    (module) => module.DocumentLinkBlock,
  ),
);
const EmployeeBlock = dynamic(() =>
  import('../employee-block/employee-block').then(
    (module) => module.EmployeeBlock,
  ),
);
const HeroBlock = dynamic(() =>
  import('../hero-block/hero-block').then((module) => module.HeroBlock),
);
const ImageBlock = dynamic(() =>
  import('../image-block/image-block').then((module) => module.ImageBlock),
);
const OurBrandsBlock = dynamic(() =>
  import('../brands-block/brands-block').then((module) => module.BrandsBlock),
);
const OfficesBlock = dynamic(() =>
  import('../offices-block/offices-block').then((mod) => mod.OfficesBlock),
);
const PlugBlock = dynamic(() =>
  import('../plug-block/plug-block').then((module) => module.PlugBlock),
);

const SimpleTitleAndTextBlock = dynamic(() =>
  import('../simple-title-and-text-block/simple-title-and-text-block').then(
    (mod) => mod.SimpleTitleAndTextBlock,
  ),
);
const SliderBlock = dynamic(() =>
  import('../slider-block/slider-block').then((module) => module.SliderBlock),
);
const StatementBlock = dynamic(() =>
  import('../statement-block/statement-block').then(
    (module) => module.StatementBlock,
  ),
);
const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((module) => module.TextBlock),
);
const TwoColumnTextImageBlock = dynamic(() =>
  import('../two-column-text-image-block/two-column-text-image-block').then(
    (module) => module.TwoColumnTextImageBlock,
  ),
);
const TwoColumnTextIntroBlock = dynamic(() =>
  import('../two-column-text-intro-block/two-column-text-intro-block').then(
    (module) => module.TwoColumnTextIntroBlock,
  ),
);
const VideoBlock = dynamic(() =>
  import('../video-block/video-block').then((module) => module.VideoBlock),
);
const NewsListBlock = dynamic(() =>
  import('../news-list-block/news-list-block').then(
    (module) => module.NewsListBlock,
  ),
);
const JobsListBlock = dynamic(() =>
  import('../jobs-list-block/jobs-list-block').then(
    (module) => module.JobsListBlock,
  ),
);

interface ContentAreaProps {
  content: ContentAreaField[];
  data?: ContentAreaData;
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content, data }) => {
  const uid = useId();

  const contentMapper = (item: ContentAreaField, index: number) => {
    const key = `Block-${uid}-${item.__typename}-${index}`;

    if (isArticlesBlock(item)) {
      return <ArticlesBlock {...item} key={key} />;
    }
    if (isBulletListBlock(item)) {
      return <BulletListBlock {...item} key={key} />;
    }
    if (isDocumentLinkBlock(item)) {
      return <DocumentLinkBlock {...item} key={key} />;
    }
    if (isEmployeeBlock(item)) {
      return <EmployeeBlock {...item} key={key} />;
    }

    if (isHeroBlock(item)) {
      return (
        <HeroBlock {...item} key={key} index={index} priority={index === 0} />
      );
    }
    if (isImageBlock(item)) {
      return <ImageBlock {...item} key={key} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock {...item} key={key} />;
    }
    if (isOurBrandsBlock(item)) {
      return <OurBrandsBlock {...item} key={key} />;
    }
    if (isPlugBlock(item)) {
      return <PlugBlock {...item} key={key} />;
    }
    if (isSimpleTitleAndTextBlock(item)) {
      return (
        <SimpleTitleAndTextBlock {...item} key={key} priority={index === 0} />
      );
    }
    if (isSliderBlock(item)) {
      return <SliderBlock {...item} key={key} priority={index === 0} />;
    }
    if (isStatementBlock(item)) {
      return <StatementBlock {...item} key={key} />;
    }
    if (isTextBlock(item)) {
      return <TextBlock {...item} key={key} />;
    }
    if (isTwoColumnTextImageBlock(item)) {
      return <TwoColumnTextImageBlock {...item} key={key} />;
    }
    if (isTwoColumnTextIntroBlock(item)) {
      return <TwoColumnTextIntroBlock {...item} key={key} />;
    }
    if (isVideoBlock(item)) {
      return <VideoBlock {...item} key={key} />;
    }
    if (isNewsListBlock(item)) {
      const fallbackData: NewsResponse[] = [];
      if (data?.[item.id]) {
        fallbackData.push(data[item.id] as NewsResponse);
      }
      return <NewsListBlock {...item} fallbackData={fallbackData} key={key} />;
    }
    if (isJobsListBlock(item)) {
      const fallbackData: JobsResponse[] = [];
      if (data?.[item.id]) {
        fallbackData.push(data[item.id] as JobsResponse);
      }
      return <JobsListBlock {...item} fallbackData={fallbackData} key={key} />;
    }

    return null;
  };

  return content?.map(contentMapper) || null;
};
