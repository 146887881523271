import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const StyledVideo = styled.div`
  display: block;
  margin: ${spacing.medium} 0;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} 0;
  }

  .video-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  video {
    align-self: center;
    max-inline-size: 100%;
    max-block-size: calc(100vh - 56px);
    object-fit: cover;
    inline-size: 100%;
  }

  .controls {
    button {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%);
      inline-size: 56px;
      block-size: 56px;
      padding: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
      cursor: pointer;
      background: rgb(33 33 38 / 60%);
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid ${colors.HGGray1};

      &:hover {
        background-color: ${colors.HGGray3};
      }

      &.visible {
        opacity: 1;
        pointer-events: initial;
        transition: opacity 0.3s ease;
      }

      svg path {
        opacity: 1;
        fill: ${colors.HGGray1};
      }

      &::before {
        @media screen and (min-width: ${breakpoints.desktop}) {
          content: '';
          position: absolute;
          block-size: 100%;
          inline-size: 100%;
          inset-block-start: 0;
          inset-inline-start: 0;
          background: rgb(33 33 38 / 20%);
          transform: translateX(-100%);
          pointer-events: none;
          z-index: -1;
        }
      }

      &:hover::before {
        transform: translateX(0);
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        inline-size: 56px;
        block-size: 56px;
      }
    }
  }

  .description {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-block-start: ${spacing.small};
    font-size: ${fontSizes.body14};

    .video-label {
      color: ${colors.HGGray3};
      margin-inline-end: ${spacing.medium};
    }

    .video-title {
      line-height: ${lineHeights.body14};
      margin-block-start: 1px;
    }
  }
`;
