import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const ContactInformationStyled = styled.div`
  padding: 0 ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }

  p,
  span,
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: ${fontWeights.fontRegular};
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    margin: 0;
    color: ${colors.black};
  }

  a {
    display: block;

    &:hover {
      color: ${colors.ctaBlue};
    }
  }
`;
