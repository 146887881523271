import dynamic from 'next/dynamic';

import { BulletListBlockFragment } from '@hultafors/hultaforsgroup/types';

import { ContentContainer } from '../content-container/content-container';

import styles from './bullet-list-block.module.scss';
import { BulletListBlockWrapper, Ul } from './bullet-list-block.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

export const BulletListBlock: React.FC<Omit<BulletListBlockFragment, 'id'>> = ({
  bullets,
  numberedList,
  title,
}) => {
  const parsedBullets =
    bullets?.split(/\r?\n/).filter((item) => !!item.trim()) || [];
  return (
    <BulletListBlockWrapper>
      <ContentContainer>
        {title && (
          <HTag type="h3" styleType="p2-paragraph" className={styles['title']}>
            {title}
          </HTag>
        )}
        <Ul as={numberedList ? 'ol' : 'ul'}>
          {parsedBullets.map((item, i) => (
            <li key={`Li-${i}`}>
              <Paragraph key={`key:${item}`}>{item}</Paragraph>
            </li>
          ))}
        </Ul>
      </ContentContainer>
    </BulletListBlockWrapper>
  );
};
