import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const SimpleTitleAndText = styled.div<{ $noMarginBottom?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: ${spacing.medium};
  margin-bottom: ${({ $noMarginBottom }) =>
    $noMarginBottom ? 0 : spacing.medium};
`;
