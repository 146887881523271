import dynamic from 'next/dynamic';

import { BulletListFragment } from '@hultafors/hultaforsgroup/types';

import { ContentContainer } from '../content-container/content-container';

import { BulletListWrapper, Ul } from './bullet-list.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface BulletListProps {
  bullets: BulletListFragment['bullets'];
  numeric?: boolean;
  title?: string;
}

export const BulletList: React.FC<BulletListProps> = ({
  bullets,
  numeric = false,
  title,
}) => {
  const parsedBullets =
    bullets?.split(/\r?\n/).filter((item) => !!item.trim()) || [];
  return (
    <BulletListWrapper>
      <ContentContainer>
        {title && (
          <HTag type="h3" styleType="h4">
            {title}
          </HTag>
        )}
        <Ul as={numeric ? 'ol' : 'ul'}>
          {parsedBullets.map((item) => (
            <li>
              <Paragraph key={`key:${item}`}>{item}</Paragraph>
            </li>
          ))}
        </Ul>
      </ContentContainer>
    </BulletListWrapper>
  );
};
