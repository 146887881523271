import React from 'react';

import clsx from 'clsx';
import Link from 'next/link';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import { CtaButtonFragment } from '@hultafors/hultaforsgroup/types';

import styles from './cta-button.module.scss';

interface CtaButtonProps extends CtaButtonFragment {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  className?: string;
  textColor?: string;
}

// TODO - Implement this component in all blocks, and remove the Button component (?)
const CtaButton: React.FC<CtaButtonProps> = ({
  text,
  url,
  onClick,
  loading,
  className,
  textColor = '#FFF',
}) => {
  const { globalContent: global } = useGlobal();
  if (url && onClick) {
    console.error("Can't have both onClick and url prop in Button component");
  }

  const hoverTextColor = {
    '--hover-text-color': textColor,
  } as React.CSSProperties;

  const extraProps =
    typeof url === 'string' &&
    (url.startsWith('http://') || url.startsWith('https://'))
      ? { rel: 'noreferrer noopener', target: '_blank' }
      : {};

  return url ? (
    <Link
      href={url}
      className={clsx(className, styles['button'])}
      style={hoverTextColor}
      {...extraProps}
    >
      {text}
    </Link>
  ) : (
    <button
      onClick={onClick}
      disabled={loading}
      className={clsx(className, styles['button'])}
      style={hoverTextColor}
    >
      {loading ? global.loading : text}
    </button>
  );
};

export default CtaButton;
