import { VideoBlockFragment } from '@hultafors/hultaforsgroup/types';

import { ContentContainer } from '../content-container/content-container';

import MuxPlayerContent from './mux-player-wrapper';
import styles from './videoBlock.module.scss';

export const VideoBlock: React.FC<Omit<VideoBlockFragment, 'id'>> = ({
  video,
  videoThumbnail,
  autoplay,
  muxVideo,
}) => {
  return video?.video ? (
    <div className={styles['video-block']}>
      <ContentContainer wide>
        <MuxPlayerContent {...{ autoplay, muxVideo, video, videoThumbnail }} />
      </ContentContainer>
    </div>
  ) : null;
};
