import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const BulletListBlockWrapper = styled.div`
  margin: ${spacing.medium} 0;
  padding: 0 ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 1em;

  li + li {
    margin-block-start: ${spacing.xsmall};
  }
`;
