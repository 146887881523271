import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { Markdown } from '../markdown/markdown';

export const EmployeeWrapper = styled.div`
  display: grid;
  margin-inline: auto;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-top: 0;
  margin-bottom: ${spacing.medium};
  padding-bottom: ${spacing.medium};
  border-bottom: 1px solid ${colors.HGGray1};
  inline-size: 62.8vw;
  max-inline-size: 900px;

  &:first-of-type & {
    padding-block-start: 0;
  }

  &:last-of-type & {
    padding-block-end: 0;
    border-block-end: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const EmployeeInnerWrapper = styled.div`
  padding-bottom: ${spacing.medium};
  border-bottom: 1px solid ${colors.HGGray1};
  ${EmployeeWrapper}:first-of-type & {
    padding-block-start: 0;
  }
  ${EmployeeWrapper}:last-of-type & {
    padding-block-end: 0;
    border-block-end: none;
  }
`;

export const TopPartWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.regular};
  flex-direction: column;
  gap: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const TitleNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: 160px;
    margin-block-end: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 2 / 3;
  inline-size: 100%;
  max-inline-size: 345px;
`;

export const Description = styled(Markdown)``;

export const Left = styled.div`
  grid-column: 1;
`;

export const Right = styled.div`
  grid-column: 1;

  :last-child > p {
    font-size: 18px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 2;
  }
`;
