import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

export const StyledSelect = styled.select`
  appearance: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.xsmall};
  border: 0;
  border-bottom: 1px solid ${colors.HGGray2};
  border-radius: 5px;
  background-color: ${colors.HGGray1};

  &:focus {
    outline: none;
  }

  option {
    padding: ${spacing.small} ${spacing.xsmall};
    line-height: 1.4em !important;

    &:disabled {
      color: ${colors.HGGray3};
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }
`;
