import { Accordion } from 'react-accessible-accordion';
import styled from 'styled-components';

// ! use shared component
export const AccordionStyled = styled(Accordion)`
  position: relative;
  width: 100%;

  @media screen and (hover: none) {
    > * + * {
      margin-block-start: 8px;
    }
  }
`;
