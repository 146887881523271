import dynamic from 'next/dynamic';
import Link from 'next/link';

import { routes } from '@hultafors/hultaforsgroup/helpers';

import styles from './article-item.module.scss';
import { ArticleItemWrapper } from './article-item.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface ArticleItemProps {
  publishDate?: string | null;
  slug?: string | null;
  title?: string | null;
  category?: string | null;
  country?: string | null;
  department?: string | null;
  isNewsArticle?: boolean;
  isJobAd?: boolean;
}

export const ArticleItem: React.FC<ArticleItemProps> = ({
  publishDate,
  slug,
  title,
  category = '',
  country = '',
  department,
  isNewsArticle = false,
  isJobAd = false,
}) => {
  const createLink = () => {
    if (isNewsArticle) {
      return `/${routes.news}/${slug}`;
    }
    if (isJobAd) {
      return `/${routes.jobs}/${slug}`;
    }
    return `/${routes.news}/${slug}`;
  };
  return (
    <ArticleItemWrapper>
      <Link href={createLink()}>
        <HTag type="p" styleType="sub-heading">{`${publishDate} - ${category}${
          country && ` - ${country}`
        }`}</HTag>
        <HTag type="h3" styleType="p2-paragraph" className={styles['title']}>
          {title}
        </HTag>
        {department && (
          <HTag type="p" styleType="sub-heading">
            {department}
          </HTag>
        )}
      </Link>
    </ArticleItemWrapper>
  );
};
