import { useId } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';

import type { ArticleListItem } from '@hultafors/hultaforsgroup/types';
import { isNewsStory } from '@hultafors/hultaforsgroup/types';

import { ArticleItem } from '../article-item/article-item';

import styles from './article-list.module.scss';

const Button = dynamic(() =>
  import('../button/button').then((module) => module.Button),
);

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface ArticleListProps {
  items: ArticleListItem[];
  title?: string | null;
  buttonText?: string | null;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  showLoadMore?: boolean;
  largeTitle?: boolean;
  children?: React.ReactNode;
}

export const ArticleList: React.FC<ArticleListProps> = ({
  title,
  items,
  showLoadMore,
  buttonOnClick,
  loading,
  buttonText,
  children,
  largeTitle,
}) => {
  const uid = useId();
  const itemMapper = (item: ArticleListItem) => {
    if (!item) {
      return null;
    }
    const isNewsArticle = isNewsStory(item);
    const isJobAd = !isNewsArticle;
    // const department = isJobAd ? item.department : '';
    return (
      <ArticleItem
        key={`ArticleList-${uid}-${item.id}`}
        publishDate={item.publishDate}
        slug={item.slug || ''}
        title={item.title || ''}
        category={item.category || ''}
        country={item.country || ''}
        // department={department}
        isNewsArticle={isNewsArticle}
        isJobAd={isJobAd}
      />
    );
  };
  if (!items?.length) {
    return null;
  }
  return (
    <section
      className={clsx('content-container', 'wide', styles['wrapper'])}
      data-test="NewsArticleList"
    >
      <HTag
        type="h2"
        styleType={largeTitle ? 'h1' : 'h2'}
        className={styles['title']}
      >
        {title}
      </HTag>
      <div className={styles['list']}>{items?.map(itemMapper)}</div>
      {showLoadMore && (
        <Button
          onClick={buttonOnClick}
          loading={loading}
          className={styles['button']}
        >
          {buttonText}
        </Button>
      )}
      {children}
    </section>
  );
};
