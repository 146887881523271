import {
  FieldWrapper,
  FieldWrapperProps,
} from '../field-wrapper/field-wrapper';
import { Label } from '../label/label';

import { StyledSelect } from './select.styled';

interface SelectProps {
  children?: React.ReactNode;
  id: string;
  inline?: boolean;
  label: string;
  options: string[];
  wrapperProps?: FieldWrapperProps;
}

export const Select: React.FC<SelectProps> = ({
  children,
  id,
  inline = false,
  label,
  options = [],
}) => (
  <FieldWrapper inline={inline}>
    <Label htmlFor={id}>{label}</Label>
    <StyledSelect id={id}>
      {children
        ? children // If children are provided, they will take precedence over the "options" prop
        : options.map((option) => (
            <option id={option} value={option} key={`select.${id}.${option}`}>
              {option}
            </option>
          ))}
    </StyledSelect>
  </FieldWrapper>
);
