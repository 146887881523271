import { MarkdownToJSX } from 'markdown-to-jsx';
import dynamic from 'next/dynamic';

import { StyledMarkdown } from './markdown.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Paragraph),
);

export interface MarkdownProps {
  children?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({ children, options }) => {
  const optionsWithHtags = {
    ...options,
    overrides: {
      h1: ({ children }: { children: React.ReactNode }) => (
        <HTag styleType="h5">{children}</HTag>
      ),
      h2: ({ children }: { children: React.ReactNode }) => (
        <HTag type="h2" styleType="h5">
          {children}
        </HTag>
      ),
      h3: ({ children }: { children: React.ReactNode }) => (
        <HTag type="h3" styleType="h5">
          {children}
        </HTag>
      ),
      h4: ({ children }: { children: React.ReactNode }) => (
        <HTag type="h4" styleType="h5">
          {children}
        </HTag>
      ),
      h5: ({ children }: { children: React.ReactNode }) => (
        <HTag type="h5" styleType="h5">
          {children}
        </HTag>
      ),
      h6: ({ children }: { children: React.ReactNode }) => (
        <HTag type="h6" styleType="h5">
          {children}
        </HTag>
      ),
      p: ({ children }: { children: React.ReactNode }) => (
        <Paragraph>{children}</Paragraph>
      ),
    },
  };

  const text = children?.replace(/&#(\d+);/g, (_search, found) => {
    const hexBase = 16;
    const unicodeLength = 4;
    const code = parseInt(found, 10)
      .toString(hexBase)
      .padStart(unicodeLength, '0');
    return JSON.parse(`"\\u${code}"`);
  });
  return (
    <StyledMarkdown options={optionsWithHtags}>{text ?? ''}</StyledMarkdown>
  );
};
