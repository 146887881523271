import dynamic from 'next/dynamic';

import { StyledContactInformationBlock } from './contact-information-block.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface ContactInformationBlockProps {
  children?: React.ReactNode;
  title?: string;
}

export const ContactInformationBlock: React.FC<
  ContactInformationBlockProps
> = ({ children, title }) => {
  return (
    <StyledContactInformationBlock>
      {title && (
        <HTag type="h2" styleType="h3">
          {title}
        </HTag>
      )}
      {children}
    </StyledContactInformationBlock>
  );
};
