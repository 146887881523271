import clsx from 'clsx';
import dynamic from 'next/dynamic';

import styles from './small-hero.module.scss';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface SmallHeroProps {
  topLabel?: string;
  title?: string;
  className?: string;
}

export const SmallHero: React.FC<SmallHeroProps> = ({
  topLabel,
  title,
  className,
}) => {
  if (!title) {
    return null;
  }

  return (
    <hgroup
      className={clsx('content-container', styles['wrapper'], className)}
      data-test="SmallHero"
    >
      {topLabel && (
        <HTag type="p" styleType="sub-heading" className={styles['label']}>
          {topLabel}
        </HTag>
      )}
      <HTag type="h1" styleType="h4">
        {title}
      </HTag>
    </hgroup>
  );
};
