import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const DesktopMenuStyled = styled.div``;

export const DesktopMenuContent = styled.div`
  width: 100%;
  max-width: ${breakpoints.maxGridWidth};
  margin-inline: auto;
  padding-inline: ${spacing.large};
  margin-block-end: ${spacing.xregular};
`;

export const DesktopMenuLink = styled(Link)`
  display: block;
  margin-block: 0;
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontRegular};
  color: ${colors.black};

  &:hover {
    color: ${colors.ctaBlue};
  }

  text-align: start;
`;

export const DesktopMenuList = styled.div`
  display: flex;
  place-content: flex-start flex-start;
  flex-flow: column wrap;
  width: 100%;
  height: calc(100vh - 156px);
  max-height: 500px;
  min-height: 280px;
  gap: 24px;

  > * {
    inline-size: 33%;
  }

  &:empty {
    display: none;
  }
`;
