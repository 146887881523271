import dynamic from 'next/dynamic';
import Link from 'next/link';

import { ContactInformationBlock } from '../contact-information-block/contact-information-block';
import { ContentContainer } from '../content-container/content-container';

import { ContactInformationStyled } from './contact-information.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface ContactInformationProps {
  description?: string;
  addressLabel?: string;
  phoneLabel?: string;
  emailLabel?: string;
  ecomEmailLabel?: string;
  ecomEmail?: string;
  localContactLabel?: string;
  companyName?: string;
  companyAddress?: string;
  companyAddress2?: string;
  companyCountry?: string;
  phoneNumber?: string;
  companyEmail?: string;
  localContactName?: string;
  localContactZipcode?: string;
  localContactAddress?: string;
  localContactAddress2?: string;
  localContactCity?: string;
  localContactCountry?: string;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  description,
  addressLabel,
  phoneLabel,
  emailLabel,
  localContactLabel,
  companyName,
  companyAddress,
  companyAddress2,
  companyCountry,
  ecomEmail,
  ecomEmailLabel,
  phoneNumber,
  companyEmail,
  localContactName,
  localContactZipcode,
  localContactAddress,
  localContactAddress2,
  localContactCity,
  localContactCountry,
}) => {
  const itemMapper = (value: string | undefined, index: number) => {
    return value ? (
      <Paragraph key={`Item-${index}-${value}`}>{value}</Paragraph>
    ) : null;
  };

  if (!description && !addressLabel) {
    return null;
  }

  return (
    <ContactInformationStyled>
      <ContentContainer>
        <HTag type="h2" styleType="h3">
          {description}
        </HTag>
        <ContactInformationBlock title={addressLabel}>
          {[companyName, companyAddress, companyAddress2, companyCountry].map(
            itemMapper,
          )}
        </ContactInformationBlock>
        {phoneNumber && (
          <ContactInformationBlock title={phoneLabel}>
            <Paragraph>
              <Link href={`tel:${phoneNumber?.replace(/[ .-]/g, '')}`}>
                {phoneNumber}
              </Link>
            </Paragraph>
          </ContactInformationBlock>
        )}
        {companyEmail && (
          <ContactInformationBlock title={emailLabel}>
            <Paragraph>
              <Link href={`mailto:${companyEmail}`}>{companyEmail}</Link>
            </Paragraph>
          </ContactInformationBlock>
        )}

        {ecomEmail && (
          <ContactInformationBlock title={ecomEmailLabel}>
            <Paragraph>
              <Link href={`mailto:${ecomEmail}`}>{ecomEmail}</Link>
            </Paragraph>
          </ContactInformationBlock>
        )}
        <ContactInformationBlock title={localContactLabel}>
          {[
            localContactName,
            localContactZipcode,
            localContactAddress,
            localContactAddress2,
            localContactCity,
            localContactCountry,
          ].map(itemMapper)}
        </ContactInformationBlock>
      </ContentContainer>
    </ContactInformationStyled>
  );
};
