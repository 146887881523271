import dynamic from 'next/dynamic';

import { ContentContainer } from '../content-container/content-container';

import { NewsLabelWrapper } from './news-label.styled';

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface NewsLabelProps {
  label?: string;
}

export const NewsLabel: React.FC<NewsLabelProps> = ({ label }) => (
  <NewsLabelWrapper>
    <ContentContainer>
      <Paragraph styleType="sub-heading">{label}</Paragraph>
    </ContentContainer>
  </NewsLabelWrapper>
);
