import dynamic from 'next/dynamic';

import type { AccordionItemClassNames } from '@hultafors/shared/components/accordion-item/accordion-item';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/hultaforsgroup/types';

import styles from './offices-block.module.scss';
import { OfficesBlockStyled } from './offices-block.styled';
import PlusSvg from './plus.svg';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItem),
);
const StoreItem = dynamic(() =>
  import('../store-item/store-item').then((mod) => mod.StoreItem),
);

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

type OfficesBlockProps = Omit<OfficesBlockFragment, 'id'>;

export const OfficesBlock: React.FC<OfficesBlockProps> = ({ offices = [] }) => {
  const { globalContent } = useGlobal();
  const { localWebLabel, viewOnMapLabel } = globalContent;
  const generateGoogleMapLinks = ({
    country,
    name,
    address,
    zipCode,
  }: OfficeFragment) => {
    return `https://www.google.com/maps/search/?api=1&query=${[
      'Hultafors Group',
      address,
      zipCode,
      country || name,
    ]
      .filter(Boolean)
      .join('+')}`;
  };

  const accordionItemClassNames: AccordionItemClassNames = {
    trigger: styles['accordion-trigger'],
  };

  const officeMapper = (office: OfficeFragment, i: number) => {
    const {
      id,
      name,
      address,
      zipCode,
      city,
      country,
      email,
      phoneNumber,
      websiteUrl,
    } = office;
    return (
      <AccordionItem
        key={`Office-${id}`}
        label={
          <HTag type="h2" styleType="p2-paragraph">
            {name}
          </HTag>
        }
        indicator={
          <PlusSvg
            aria-hidden={true}
            focusable={false}
            width={16}
            height={16}
          />
        }
        value={`accordion-${name}-${i}`}
        classNames={accordionItemClassNames}
      >
        <StoreItem
          address={address || ''}
          zipCode={zipCode || ''}
          city={city || ''}
          country={country || name || ''}
          mapLink={generateGoogleMapLinks(office)}
          view_on_map={viewOnMapLabel}
          email={email || ''}
          phone={phoneNumber || ''}
          websiteUrl={websiteUrl || ''}
          visit_web_site={localWebLabel}
        />
      </AccordionItem>
    );
  };
  if (!offices.length) {
    return null;
  }
  return (
    <OfficesBlockStyled>
      <Accordion>{offices.map(officeMapper)}</Accordion>
    </OfficesBlockStyled>
  );
};
