export const colors = {
  HGGray1: '#f6f5ef',
  HGGray2: '#e5e5e0',
  HGGray3: '#b8b6b1',
  black: '#000000',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  ctaBlue: '#5042f2',
  hellNo: '#FF1E1E',
  hellYeah: '#67FC6D',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  textShadowBlack: 'rgba(33, 33, 38, .8)',
  textShadowWhite: 'rgba(255, 255, 255, .8)',
  white: '#FFFFFF',
  // Legacy
  yellow: 'rgba(242, 201, 76, 1)',
} as const;
