import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

import { StyledTextButton } from '../text-button/text-button.styled';

/* import { TextButton } from '../TextButton/style'; */

export const FooterStyled = styled.div`
  width: 100%;
  margin-top: auto;
  background-color: ${colors.black};
  color: ${colors.white};

  .Grid {
    position: relative;
    max-inline-size: ${breakpoints.desktopLarger};
    padding: 0;
  }

  section {
    padding: ${spacing.medium} ${spacing.regular};
    margin: 0;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: ${spacing.medium};
    }
  }
`;

export const StartSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.medium};
  align-items: flex-start;
  gap: ${spacing.small};

  /* img {
    margin-bottom: ${spacing.small};
  } */
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    /* img {
      margin-bottom: initial;
    } */
  }

  /* ${StyledTextButton} {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-left: auto;
    }
  } */
`;

export const StartActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.regular};
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex-basis: 50%;
  text-align: start;

  .IntroText {
    padding-block-end: ${spacing.regular};
  }

  .markdown-wrapper {
    padding-block-end: 4px;
  }

  p,
  a {
    margin-block-start: 0;
    max-inline-size: 600px;
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    color: ${colors.white};
    padding: 4px;

    &:hover {
      color: ${colors.white};
    }
  }

  .Divider {
    color: ${colors.white};
  }

  .BrandLink {
    display: inline-block;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: 0;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontRegular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: flex-end;
  }

  .BottomSectionLinks {
    a,
    button {
      cursor: pointer;
      text-decoration: none;
      color: ${colors.white};
      font-weight: ${fontWeights.fontRegular};
      padding: 0 !important;
    }

    button {
      margin-inline-start: 3px;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  padding-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block-end: 0;
  }

  a {
    inline-size: 32px;
    block-size: 32px;

    img {
      inline-size: 100%;
    }

    &:not(:last-of-type) {
      margin-inline-end: ${spacing.small};
    }
  }
`;

export const LogoLink = styled(Link)`
  svg {
    block-size: 16px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      block-size: 20px;
    }
  }
`;
