import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerWideStyle } from '../content-container/content-container.styled';

export const TwoColumnTextImageWrapper = styled.div<{ $reverse?: boolean }>`
  ${contentContainerWideStyle};
  display: grid;
  gap: ${spacing.regular};
  margin-block: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
    margin-block: ${spacing.large};
    ${({ $reverse }) =>
      $reverse &&
      css`
        > *:last-child {
          order: -1;
        }
      `}
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const ImageWrapper = styled.div<{
  $aspectRatio?: CSSProperties['aspectRatio'];
}>`
  position: relative;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '3 / 2'};
`;
