import styled from 'styled-components';

import { colors } from '@hultafors/hultaforsgroup/helpers';

export const ArticleItemWrapper = styled.article`
  padding: 32px 0 16px;

  &:not(:last-child) {
    border-block-end: 1px solid ${colors.HGGray2};
  }

  &:only-child {
    border-block-end: 1px solid ${colors.HGGray2};
  }
`;
