import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

export const SectionStyled = styled.section`
  padding: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.medium} ${spacing.medium};
  }

  &.SmallMarginTop {
    padding-block-start: ${spacing.small};
  }

  &.LargeMarginTop {
    padding-block-start: ${spacing.large};
  }

  &.SmallMarginBottom {
    padding-block-end: ${spacing.small};
  }

  &.MediumMarginBottom {
    padding-block-end: ${spacing.regular};
  }

  &.LargeMarginBottom {
    padding-block-end: ${spacing.large};
  }

  &.MegaMarginBottom {
    padding-block-end: ${spacing.XL};
  }

  &.FullWidth {
    padding-inline: 0;
  }

  &.BorderTop {
    border-block-start: 1px solid ${colors.HGGray2};
  }

  &.BorderBottom {
    border-block-end: 1px solid ${colors.HGGray2};
  }

  &.NoMarginBottom {
    padding-block-end: 0;
    margin-block-end: 0;
  }

  &.NoMarginTop {
    margin-block-start: 0;
    padding-block-start: 0;
  }

  &.NoMargin {
    margin: 0;
    padding: 0;
  }

  &.MaxWidth {
    max-inline-size: ${breakpoints.maxPageWidth};

    @media screen and (min-width: ${breakpoints.maxPageWidth}) {
      inline-size: 100%;
      margin: 0 auto;
      padding-inline: 0;
    }
  }
`;
