import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const StyledContactInformationBlock = styled.div`
  margin-bottom: ${spacing.xregular};

  &:last-of-type {
    margin-block-end: ${spacing.large};
  }
`;
