import Image from 'next/image';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const BrandPageBlockWrapper = styled.div`
  display: block;
  margin: ${spacing.large} 0;
  padding: 0 ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const StyledImage = styled(Image)`
  margin-bottom: ${spacing.medium};
`;
