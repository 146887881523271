import dynamic from 'next/dynamic';
import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { EmployeeFragment } from '@hultafors/hultaforsgroup/types';

import {
  Description,
  EmployeeWrapper,
  ImageWrapper,
  Left,
  Right,
  TitleNameWrapper,
} from './employee.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

type EmployeeProps = EmployeeFragment;

export const Employee: React.FC<EmployeeProps> = ({
  title,
  introText,
  description,
  name,
  profileImage,
}) => {
  const sizes = [
    `(min-width: ${breakpoints.mobileMax}) 160px`,
    `(min-width: ${breakpoints.mediumMobileMax}) 375px`,
    '100vw',
  ].join(', ');
  return (
    <EmployeeWrapper>
      {profileImage?.responsiveImage?.src && (
        <Left>
          <ImageWrapper>
            <Image
              src={profileImage.responsiveImage.src}
              alt={profileImage.alt || name || ''}
              blurDataURL={profileImage.responsiveImage?.base64 || ''}
              placeholder={
                profileImage.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes={sizes}
              fill
            />
          </ImageWrapper>
        </Left>
      )}
      <Right>
        {title && name && (
          <TitleNameWrapper>
            <HTag type="h2">{name}</HTag>
            <Paragraph styleType="sub-heading">{title}</Paragraph>
          </TitleNameWrapper>
        )}
        {introText && (
          <HTag type="h3" styleType="h4">
            {introText}
          </HTag>
        )}
        {description && <Description>{description}</Description>}
      </Right>
    </EmployeeWrapper>
  );
};
