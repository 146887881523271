import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

interface StyledTextButtonProps {
  $arrow?: boolean;
  $variant?: 'black' | 'white';
  $icon?: React.ReactNode;
  $iconSize?: number;
  $loading?: boolean;
}

export const StyledTextButton = styled.button<StyledTextButtonProps>`
  /* Reset */
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;

  /* Styles */
  display: inline-flex;
  align-items: center;
  height: ${spacing.medium};
  color: ${({ $variant = 'black' }) =>
    $variant === 'black' ? colors.black : colors.white};
  text-decoration: none;
  padding: ${spacing.xsmall} 0;
  cursor: pointer;
  transition: 0.2s;

  /* Text style */
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
  font-weight: ${fontWeights.fontRegular};

  /* In case of Icon */
  svg {
    inline-size: ${({ $iconSize }) => `${$iconSize}px`};
    block-size: ${({ $iconSize }) => `${$iconSize}px`};
    margin-inline-start: ${spacing.xsmall};

    path,
    circle,
    line {
      fill: ${({ $variant = 'black' }) =>
        $variant === 'black' ? colors.black : colors.white};
      transition: 0.2s;
    }
  }

  img {
    transition: 0.2s;
    inline-size: ${({ $iconSize }) => `${$iconSize}px`};
    block-size: ${({ $iconSize }) => `${$iconSize}px`};
    margin-inline-start: ${spacing.xsmall};
  }

  :hover {
    color: ${({ $variant = 'black', $arrow }) =>
      !$arrow && ($variant === 'black' ? colors.black : colors.HGGray1)};

    svg {
      * {
        fill: ${({ $variant = 'black', $arrow }) =>
          !$arrow && ($variant === 'black' ? colors.black : colors.HGGray1)};
      }
    }
  }

  ${({ $icon, $arrow }) =>
    !$icon &&
    !$arrow &&
    css`
      text-decoration: underline;
    `}

  ${({ $arrow }) =>
    $arrow &&
    css`
      font-size: ${fontSizes.label};
      line-height: ${lineHeights.label};
      font-weight: ${fontWeights.fontMedium};
      letter-spacing: 0.1em;
      text-transform: uppercase;

      img {
        inline-size: fit-content;
        block-size: fit-content;
      }

      &:hover {
        img {
          margin-inline-start: ${spacing.small};
        }
      }
    `}
`;
