import { useState } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import type { AccordionItemClassNames } from '@hultafors/shared/components/accordion-item/accordion-item';

import { createSubMenuLink, spacing } from '@hultafors/hultaforsgroup/helpers';
import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  ItemRecord,
  MenuItemFragment,
  SubMenuItemFragment,
} from '@hultafors/hultaforsgroup/types';

import PartnerSvg from '../../svg/partner.svg';
import { SlideIn, SlideInToggler } from '../slide-in/slide-in';

import styles from './mobile-menu.module.scss';
import {
  MenuStyled,
  MenuWrapper,
  PartnerLoginLink,
} from './mobile-menu.styled';
import PlusSvg from './plus.svg';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItem),
);

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

interface MobileMenuProps {
  toggleMenu: SlideInToggler;
  headerText: string;
  menuItems: MenuItemFragment[];
  menuType?: string;
  partnerPortalUrl: string;
  partnerLoginLabel: string;
  isOpen: boolean;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  menuItems = [],
  partnerPortalUrl = '/',
  partnerLoginLabel = '',
  headerText = 'Stuff',
  isOpen = false,
  toggleMenu,
  menuType,
  ...props
}) => {
  const { globalContent: global } = useGlobal();
  const [focus, _setFocus] = useState(false);

  // const getNodeClass = (node, index, parentNodesLength) => {
  //   if (node.isParent) {
  //     return 'Parent';
  //   } else {
  //     if (parentNodesLength === index + 1) {
  //       return 'Child LastChild';
  //     }
  //   }
  //   return 'Child';
  // };

  const accordionItemClassNames: AccordionItemClassNames = {
    item: styles['accordion-item'],
    trigger: styles['accordion-trigger'],
  };

  const menuItemMapper = (item: MenuItemFragment, i: number) => {
    const key = `MobileMenuItem-${item.id}`;
    const onClick = () => toggleMenu();
    if (item.submenu) {
      // let parent = false;
      const subMenuMapper = (subItem: SubMenuItemFragment, j: number) => {
        // if (subItem.isParent) {
        //   parent = subItem;
        // }
        return (
          <Link
            key={`SubItemLink-${i}-${j}`}
            href={createSubMenuLink(subItem as ItemRecord)}
            onClick={onClick}
            className={clsx(
              styles['item-link'],
              styles['item-link--sub-item'],
              'h3',
            )}
          >
            {subItem.title}
          </Link>
        );
      };
      const label = item.title ? (
        <HTag type="span" styleType="h2">
          {item.title}
        </HTag>
      ) : (
        ''
      );
      return (
        <AccordionItem
          key={key}
          label={label}
          value={`accordion-${item.title}-${i}`}
          indicator={<PlusSvg aria-hidden={true} focusable={false} />}
          classNames={accordionItemClassNames}
        >
          {item.submenu.menuItems.map(subMenuMapper)}
        </AccordionItem>
      );
    } else {
      return (
        <Link
          key={key}
          href={item.url || ''}
          onClick={onClick}
          className={styles['item-link']}
        >
          {item.title}
        </Link>
      );
    }
  };

  return (
    <MenuStyled data-test="MobileMenu">
      {menuItems.length > 0 && (
        <SlideIn
          toggle={toggleMenu}
          isOpen={isOpen}
          headerText={global.menu || 'Menu'}
          isMenu
          padContent
          id="mobileMenu"
        >
          <MenuWrapper $hide={focus}>
            <Accordion>{menuItems.map(menuItemMapper)}</Accordion>

            <PartnerLoginLink
              href={partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {partnerLoginLabel}
              <PartnerSvg width={spacing.small} height={spacing.small} />
            </PartnerLoginLink>
          </MenuWrapper>
        </SlideIn>
      )}
    </MenuStyled>
  );
};
