import dynamic from 'next/dynamic';

import { ArticlesBlockFragment } from '@hultafors/hultaforsgroup/types';

import { ArticleItem } from '../article-item/article-item';
import { Button } from '../button/button';

import {
  ArticlesBlockStyled,
  ButtonWrapper,
  List,
} from './articles-block.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

export const ArticlesBlock: React.FC<Omit<ArticlesBlockFragment, 'id'>> = ({
  ctaText,
  ctaUrl,
  isJobArticles,
  jobPages,
  newsPages,
  title,
}) => {
  // Preventing editor from linking to two types of pages
  // at the same time.
  if (jobPages.length && newsPages.length) {
    return null;
  }

  let articlesList;

  if (isJobArticles) {
    articlesList = jobPages;
  } else {
    articlesList = newsPages;
  }
  return (
    <ArticlesBlockStyled data-test="ArticlesBlock">
      <HTag type={'h2'}>{title}</HTag>

      <List>
        {articlesList?.map((item, index) => {
          return (
            <ArticleItem
              key={`key${index}`}
              publishDate={item.publishDate}
              slug={item.slug || ''}
              title={item.title || ''}
              category={item.category || ''}
              country={item.country || ''}
              isNewsArticle={!isJobArticles}
              isJobAd={isJobArticles}
            />
          );
        })}
      </List>
      {ctaText && ctaUrl && (
        <ButtonWrapper>
          <Button to={ctaUrl || ''}>{ctaText}</Button>
        </ButtonWrapper>
      )}
    </ArticlesBlockStyled>
  );
};
